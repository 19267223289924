<template>
  <div>
    <a-modal
      v-drag-modal
      :body-style="bodyStyle"
      class="modal-container"
      :visible="true"
      :title="title + '记录——' + record.taskCode"
      :mask-closable="false"
      width="800px"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button @click="handleReset">重置</a-button>
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
      </template>
      <a-form-model ref="ruleForm" :model="record" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="记录类型" prop="recordType">
          <a-radio-group v-model="record.recordType" :disabled="isUpdate" @change="onRadioChange">
            <a-radio :value="1">加工记录</a-radio>
            <a-radio :value="2">检验记录</a-radio>
            <a-radio :value="3">返工加工记录</a-radio>
            <a-radio :value="4">返工检验记录</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="记录人员" prop="userId">
          <a-select
            v-model="record.userId"
            show-search
            placeholder="请选择记录人员"
            style="width: 300px"
            option-filter-prop="children"
            :filter-option="filterUser"
            @select="handleUserSelected"
          >
            <a-select-option v-for="user in currentUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="工序" prop="operationId">
          <a-select
            v-model="record.operationId"
            :loading="checkLoading"
            show-search
            placeholder="请选择工序"
            style="width: 300px"
            option-filter-prop="children"
            @change="checkOperationVaild"
          >
            <template v-if="!checkLoading && operationVaild">
              <a-icon slot="suffixIcon" type="check" />
            </template>
            <a-select-option v-for="operation in currentOperationList" :key="operation.id" :value="operation.id">
              {{ operation.operationName }}({{
              operation.operationCode
              }})
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="operationName === '下料'" label="期望下料时间">
          <span style="color:red; font-size:20px">{{ record.expectMaterialTime }}</span></a-form-model-item>
        <a-form-model-item label="实发材料" prop="actualUseMaterial" v-if="operationName === '下料'">
          <a-select
            mode="multiple"
            v-model="actualUseMaterial"
            :loading="checkLoading"
            show-search
            :default-active-first-option="false"
            placeholder="请选择实发材料"
            style="width: 460px"
            option-filter-prop="children"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="fetching ? undefined : null"
            @focus="handleFetchALlMaterial"
            @search="fetchMaterialByCode"
            @select="(value, option) => handleMaterialNameChange(value, option)"
            @deselect="(value, option) => handleMaterialNameChange(value, option)"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option
              v-for="material in materials"
              :key="material.id"
            >{{ material.innerCode }} {{ material.materialName }}({{ material.materialCode }})</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="record.operationId === 263" label="入库提示">{{ checkNextOperationName }}</a-form-model-item>
        <a-form-model-item v-if="record.operationId === 263" label="入库库房" prop="warehouseType">
          <a-select v-model="record.warehouseType" placeholder="请选择入库库房">
            <a-select-option :value="0">成品库</a-select-option>
            <a-select-option :value="1">半成品库</a-select-option>
            <a-select-option :value="2">工装库</a-select-option>
            <a-select-option :value="3">材料库</a-select-option>
            <a-select-option :value="4">废品库</a-select-option>
            <a-select-option :value="5">来料退回库</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="isOperationRecord" label="交检数量">
          <a-input v-model.number="record.checkCount" :max-length="10" :disabled="true" placeholder="请输入交检数量" />
        </a-form-model-item>
        <a-form-model-item v-if="isOperationRepairRecord" label="交检数量" prop="checkCount">
          <a-input v-model.number="record.checkCount" :max-length="10" placeholder="请输入交检数量" />
        </a-form-model-item>
        <a-form-model-item v-if="isOperationRecord || isOperationRepairRecord" label="实际交检数量" prop="actualCheckCount">
          <a-input v-model.number="record.actualCheckCount" :max-length="10" placeholder="请输入实际交检数量" />
        </a-form-model-item>
        <a-form-model-item v-if="isCheckRepairRecord" label="流转数量" prop="checkCount">
          <a-input v-model.number="record.checkCount" :max-length="10" placeholder="请输入流转数量" />
        </a-form-model-item>
        <a-form-model-item v-if="isCheckRecord || isCheckRepairRecord" has-feedback label="报废数量" prop="abandonCount">
          <a-input v-model.number="record.abandonCount" :max-length="10" placeholder="请输入报废数量" />
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.abandonCount > 0
          "
          label="报废责任人"
          prop="abandonChargerIds"
        >
          <a-select
            v-model="record.abandonChargerIds"
            mode="multiple"
            show-search
            placeholder="请选择报废责任人"
            option-filter-prop="children"
            :filter-option="filterUser"
          >
            <a-select-option v-for="user in allUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.abandonCount > 0
          "
          label="报废责任工序"
          prop="abandonChargeOperationIds"
        >
          <a-select
            v-model="record.abandonChargeOperationIds"
            mode="multiple"
            show-search
            placeholder="请选择报废责任工序"
            option-filter-prop="children"
            :filter-option="filterOperation"
          >
            <a-select-option v-for="operation in allOperationList" :key="operation.id" :value="operation.id">
              {{ operation.operationName }}({{
              operation.operationCode
              }})
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.abandonCount > 0
          "
          label="报废原因"
          prop="abandonReasonIds"
        >
          <a-select
            v-model="record.abandonReasonIds"
            mode="multiple"
            show-search
            placeholder="请选择报废原因"
            option-filter-prop="children"
            :filter-option="filterReason"
          >
            <a-select-option v-for="reason in allDefeatReasons" :key="reason.id" :value="reason.id">{{ reason.parameterValue }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.abandonCount > 0
          "
          label="材料规格"
          prop="abandonContactRecord"
        >
          <a-input v-model="record.abandonContactRecord" :max-length="50" placeholder="请输入材料规格" />
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.abandonCount > 0
          "
          label="报废品描述"
          prop="abandonDefeatDesc"
        >
          <a-textarea v-model="record.abandonDefeatDesc" :max-length="255" placeholder="请输入报废品描述" />
        </a-form-model-item>
        <a-form-model-item v-if="isCheckRecord || isCheckRepairRecord" has-feedback label="返工数量" prop="repairedCount">
          <a-input v-model.number="record.repairedCount" :max-length="10" placeholder="请输入返工数量" />
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.repairedCount > 0
          "
          label="返工责任人"
          prop="repairedChargerIds"
        >
          <a-select
            v-model="record.repairedChargerIds"
            mode="multiple"
            show-search
            placeholder="请选择返工责任人"
            option-filter-prop="children"
            :filter-option="filterUser"
          >
            <a-select-option v-for="user in allUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.repairedCount > 0
          "
          label="返工责任工序"
          prop="repairedChargeOperationIds"
        >
          <a-select
            v-model="record.repairedChargeOperationIds"
            mode="multiple"
            show-search
            placeholder="请选择返工责任工序"
            option-filter-prop="children"
            :filter-option="filterOperation"
          >
            <a-select-option v-for="operation in allOperationList" :key="operation.id" :value="operation.id">
              {{ operation.operationName }}({{
              operation.operationCode
              }})
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.repairedCount > 0
          "
          label="返工原因"
          prop="repairedReasonIds"
        >
          <a-select
            v-model="record.repairedReasonIds"
            mode="multiple"
            show-search
            placeholder="请选择返工原因"
            option-filter-prop="children"
            :filter-option="filterReason"
          >
            <a-select-option v-for="reason in allDefeatReasons" :key="reason.id" :value="reason.id">{{ reason.parameterValue }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.repairedCount > 0
          "
          label="返工人"
          prop="repairedUserIds"
        >
          <a-select
            v-model="record.repairedUserIds"
            mode="multiple"
            show-search
            placeholder="请选择返工人"
            option-filter-prop="children"
            :filter-option="filterUser"
          >
            <a-select-option v-for="user in allUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.repairedCount > 0
          "
          label="返工工序"
          prop="repairedOperationIds"
        >
          <a-select
            v-model="record.repairedOperationIds"
            mode="multiple"
            show-search
            placeholder="请选择返工工序"
            option-filter-prop="children"
            :filter-option="filterOperation"
          >
            <a-select-option v-for="operation in allOperationList" :key="operation.id" :value="operation.id">
              {{ operation.operationName }}({{
              operation.operationCode
              }})
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.repairedCount > 0
          "
          label="返修要求"
          prop="defeatDemand"
        >
          <a-input v-model="record.defeatDemand" :max-length="50" placeholder="请输入返修要求" />
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.repairedCount > 0
          "
          label="不合格详情"
          prop="repairDefeatDesc"
        >
          <a-textarea v-model="record.repairDefeatDesc" :max-length="255" placeholder="请输入不合格详情" />
        </a-form-model-item>
        <a-form-model-item v-if="isCheckRecord || isCheckRepairRecord" has-feedback label="超差数量" prop="outOfToleranceCount">
          <a-input v-model.number="record.outOfToleranceCount" :max-length="10" placeholder="请输入超差数量" />
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.outOfToleranceCount > 0
          "
          label="超差责任人"
          prop="outOfToleranceChargerIds"
        >
          <a-select
            v-model="record.outOfToleranceChargerIds"
            mode="multiple"
            show-search
            placeholder="请选择超差责任人"
            option-filter-prop="children"
            :filter-option="filterUser"
          >
            <a-select-option v-for="user in allUserList" :key="user.id" :value="user.id">{{ user.realName }}({{ user.uid }})</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.outOfToleranceCount > 0
          "
          label="超差责任工序"
          prop="outOfToleranceChargeOperationIds"
        >
          <a-select
            v-model="record.outOfToleranceChargeOperationIds"
            mode="multiple"
            show-search
            placeholder="请选择超差责任工序"
            option-filter-prop="children"
            :filter-option="filterOperation"
          >
            <a-select-option v-for="operation in allOperationList" :key="operation.id" :value="operation.id">
              {{ operation.operationName }}({{
              operation.operationCode
              }})
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.outOfToleranceCount > 0
          "
          label="超差原因"
          prop="outOfToleranceReasonIds"
        >
          <a-select
            v-model="record.outOfToleranceReasonIds"
            mode="multiple"
            show-search
            placeholder="请选择超差原因"
            option-filter-prop="children"
            :filter-option="filterReason"
          >
            <a-select-option v-for="reason in allDefeatReasons" :key="reason.id" :value="reason.id">{{ reason.parameterValue }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.outOfToleranceCount > 0
          "
          label="顾客意见"
          prop="outOfToleranceContactRecord"
        >
          <a-input v-model="record.outOfToleranceContactRecord" :max-length="50" placeholder="请输入顾客意见" />
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              (isCheckRecord || isCheckRepairRecord) &&
              record.outOfToleranceCount > 0
          "
          label="不合格详情"
          prop="outOfToleranceDefeatDesc"
        >
          <a-textarea v-model="record.outOfToleranceDefeatDesc" :max-length="255" placeholder="请输入不合格详情" />
        </a-form-model-item>
        <a-form-model-item v-if="isAdd && (isOperationRecord || isOperationRepairRecord)" label="是否自检" prop="isSelfCheck">
          <a-radio-group v-model="record.isSelfCheck">
            <a-radio :value="false">非自检</a-radio>
            <a-radio :value="true">自检</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            isAdd &&
              record.isSelfCheck &&
              (isOperationRecord || isOperationRepairRecord)
          "
          label="下一加工工序"
          prop="nextOperationId"
        >
          <a-select v-model="record.nextOperationId" show-search placeholder="请选择下一加工工序" style="width: 300px" option-filter-prop="children">
            <a-select-option v-for="operation in allOperationList" :key="operation.id" :value="operation.id">
              {{ operation.operationName }}({{
              operation.operationCode
              }})
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="交检/检验时间" prop="generateTime">
          <a-date-picker v-model="record.generateTime" show-time disabled type="date" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="comment">
          <a-input v-model.trim="record.comment" :max-length="1024" placeholder="请输入备注(不超过1000个字)" type="textarea" />
        </a-form-model-item>
        <a-form-model-item v-if="isCheckRecord && isAdd" label="当前流转数量">
          {{
          currentCirculationNum
          }}
        </a-form-model-item>
        <a-form-model-item v-if="isCheckRecord && isAdd" label="流转数量确认" prop="circulationNum">
          <a-input v-model.number="record.circulationNum" :max-length="10" placeholder="请输入流转数量" />
        </a-form-model-item>
        <a-form-model-item v-if="isCheckRecord || isCheckRepairRecord" label="下一加工工序" prop="nextOperationId">
          <a-select v-model="record.nextOperationId" show-search placeholder="请选择下一加工工序" style="width: 300px" option-filter-prop="children">
            <a-select-option v-for="operation in allOperationList" :key="operation.id" :value="operation.id">
              {{ operation.operationName }}({{
              operation.operationCode
              }})
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="isCheckRecord || isCheckRepairRecord" label="当前瓶颈工序">
          <span style="color:rgb(255, 174, 0); font-size:20px">{{ bottleneckOperations.join(', ') }}</span></a-form-model-item>
        <a-form-model-item v-if="isCheckRecord && isAdd" label="是否打印合格证" prop="certificate">
          <a-radio-group v-model="record.certificate">
            <a-radio :value="0">否</a-radio>
            <a-radio :value="1">是</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="isAdd && record.recordType === 4" label="返工是否结束" prop="whetherRepairFinished">
          <a-radio-group v-model="record.whetherRepairFinished">
            <a-radio :value="false">继续返修</a-radio>
            <a-radio :value="true">结束返修</a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="printVisible" title="请选择要打印的单据？" ok-text="打印完成" cancel-text="暂不打印" @ok="handleCardConfirm" @cancel="handleCardCancel">
      <div class="card-list">
        <el-link v-if="record.repairedCount > 0" type="success" @click="handleJump('rework')">不合格品返工处置通知单</el-link>
        <el-link v-if="record.outOfToleranceCount > 0" type="success" @click="handleJump('overTolerance')">不合格品超差处置单</el-link>
        <el-link v-if="record.abandonCount > 0" type="success" @click="handleJump('scrap')">产品报废单</el-link>
      </div>
    </a-modal>

    <RecordAssembleEdit
      v-if="assembleRecordEditVisible"
      :assemble-form="assembleForm"
      @cancel="handleRecordAssembleModalCanceled"
      @confirm="handleRecordAssembleModalConfirmed"
    />

    <RecordTaskFinishModal
      v-if="taskFinishModalVisible"
      :task-finish-user-id="taskFinishUserId"
      :task-finish-plan-id="taskFinishPlanId"
      @cancel="finishPartsTasksCancel"
      @confirm="finishPartsTasksConfirm"
      @ignore="finishPartsTaskIgnore"
    />
  </div>
</template>
<script>
import { updateRecord, getPartsTaskByPlan } from '@/api/record'
import {
  getTaskById,
  addRecordByTask,
  addRecordAndBatchTask,
  getOperationCheck
} from '@/api/task'
import { getAllUsers } from '@/api/user'
import {
  getMaterialByMaterialCode,
  getAllMaterialByMaterialCode
} from '@/api/material'
import { getAllOperations, getLastestCheckOperations } from '@/api/operation'
import * as R from 'ramda'
import RecordAssembleEdit from '@/components/RecordAssembleEdit'
import RecordTaskFinishModal from '@/components/RecordTaskFinishModal'
import debounce from 'lodash/debounce'
import { getAllParametersByParentCode,getBottleneckParameter } from '@/api/parameter'

export default {
  components: {
    RecordAssembleEdit,
    RecordTaskFinishModal
  },
  props: {
    modalTitle: String,
    recordForm: Object
  },
  data() {
    this.fetchMaterialByCode = debounce(this.fetchMaterialByCode, 800)
    const checkNumber = (rule, value, callback) => {
      if (value !== 0 && !value) {
        return callback(new Error('请输入数量'))
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字'))
      } else if (value < 0 || value > this.initCirculationNum) {
        const errString =
          '输入不能小于0或超过流转数量' + this.initCirculationNum
        callback(new Error(errString))
      } else {
        callback()
      }
    }
    const checkCirculationNum = (rule, value, callback) => {
      if (value !== 0 && !value) {
        return callback(new Error('请输入数量'))
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字'))
      } else if (value < 0 || value > this.currentCirculationNum) {
        const errString =
          '输入不能小于0或超过当前流转数量' + this.currentCirculationNum
        callback(new Error(errString))
      } else {
        callback()
      }
    }
    return {
      title: this.modalTitle,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 15,
        offset: 1
      },
      bodyStyle: {
        maxHeight: '700px',
        overflowY: 'scroll'
      },
      userQueryParams: {},
      operationQueryParams: {},
      loading: false,
      allUserList: [],
      currentUserList: [],
      allOperationList: [],
      currentOperationList: [],
      assembleRecordEditVisible: false,
      currentTask: {},
      assembleForm: {},
      actualUseMaterial: [],
      operationName: '',
      record: {
        id: this.recordForm.id,
        recordType: this.recordForm.recordType,
        taskId: this.recordForm.taskId,
        taskCode: this.recordForm.taskCode,
        operationId: this.recordForm.operationId,
        actualUseMaterial: this.recordForm.actualUseMaterial || [],
        nextOperationId: this.recordForm.nextOperationId,
        checkCount: this.recordForm.checkCount,
        actualCheckCount: this.recordForm.actualCheckCount,
        abandonCount: this.recordForm.abandonCount,
        outOfToleranceCount: this.recordForm.outOfToleranceCount,
        repairedCount: this.recordForm.repairedCount,
        userId: this.recordForm.userId,
        generateTime: this.recordForm.generateTime,
        expectMaterialTime: this.recordForm.expectMaterialTime,
        comment: this.recordForm.comment,
        circulationNum: 0,
        isSelfCheck: this.recordForm.isSelfCheck,
        allDefeatReasons: [],
        abandonChargerIds: [],
        abandonReasonIds: [],
        abandonChargeOperationIds: [],
        repairedChargerIds: [],
        repairedReasonIds: [],
        repairedChargeOperationIds: [],
        repairedUserIds: [],
        repairedOperationIds: [],
        outOfToleranceChargerIds: [],
        outOfToleranceReasonIds: [],
        outOfToleranceChargeOperationIds: [],
        certificate: 0,
        whetherRepairFinished: null,
        abandonContactRecord: '',
        abandonDefeatDesc: '',
        repairDefeatDesc: '',
        outOfToleranceDefeatDesc: '',
        outOfToleranceContactRecord: '',
        defeatDemand: '',
        whetherMaterialReturn: false,
        warehouseType: 0
      },
      rules: {
        recordType: [
          {
            required: true,
            message: '请选择一种类型',
            trigger: 'change'
          }
        ],
        checkCount: [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change'
          }
        ],
        actualCheckCount: [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change'
          }
        ],
        abandonCount: [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change'
          }
        ],
        outOfToleranceCount: [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change'
          }
        ],
        repairedCount: [
          {
            required: true,
            validator: checkNumber,
            trigger: 'change'
          }
        ],
        circulationNum: [
          {
            required: true,
            validator: checkCirculationNum,
            trigger: 'change'
          }
        ],
        userId: [
          {
            required: true,
            message: '请选择记录人员',
            trigger: 'change'
          }
        ],
        operationId: [
          {
            required: true,
            message: '请选择工序',
            trigger: 'change'
          }
        ],
        abandonChargerIds: [
          {
            required: true,
            message: '请选择报废责任人',
            trigger: 'change'
          }
        ],
        abandonChargeOperationIds: [
          {
            required: true,
            message: '请选择报废责任工序',
            trigger: 'change'
          }
        ],
        repairedChargerIds: [
          {
            required: true,
            message: '请选择返工责任人',
            trigger: 'change'
          }
        ],
        repairedChargeOperationIds: [
          {
            required: true,
            message: '请选择返工责任工序',
            trigger: 'change'
          }
        ],
        repairedUserIds: [
          {
            required: true,
            message: '请选择返工人',
            trigger: 'change'
          }
        ],
        repairedOperationIds: [
          {
            required: true,
            message: '请选择返工工序',
            trigger: 'change'
          }
        ],
        outOfToleranceChargerIds: [
          {
            required: true,
            message: '请选择超差责任人',
            trigger: 'change'
          }
        ],
        outOfToleranceChargeOperationIds: [
          {
            required: true,
            message: '请选择超差责任人',
            trigger: 'change'
          }
        ],
        abandonReasonIds: [
          {
            required: true,
            message: '请选择报废原因',
            trigger: 'change'
          }
        ],
        repairedReasonIds: [
          {
            required: true,
            message: '请选择返工原因',
            trigger: 'change'
          }
        ],
        outOfToleranceReasonIds: [
          {
            required: true,
            message: '请选择超差原因',
            trigger: 'change'
          }
        ],
        isSelfCheck: [
          {
            required: true,
            message: '请选择是否自检',
            trigger: 'change'
          }
        ],
        nextOperationId: [
          {
            required: true,
            message: '请选择下一加工工序',
            trigger: 'change'
          }
        ],
        certificate: [
          {
            required: true,
            message: '请选择是否打印合格证',
            trigger: 'change'
          }
        ],
        whetherMaterialReturn: [
          {
            required: true,
            message: '请选择是否来料退回',
            trigger: 'change'
          }
        ],
        whetherRepairFinished: [
          {
            required: true,
            message: '请选择返工是否结束',
            trigger: 'change'
          }
        ],
        warehouseType: [
          {
            required: true,
            message: '请选择返入库库房',
            trigger: 'change'
          }
        ]
      },
      checkLoading: false,
      operationVaild: false,
      printVisible: false,
      recordId: '',
      cradData: {},
      submitData: {},
      taskFinishModalVisible: false,
      taskFinishForm: {},
      taskFinishPlanId: this.recordForm.planId,
      taskFinishUserId: '',
      taskFinishContinute: true,
      checkNextOperationName: '',
      lastFetchId: 0,
      bottleneckOperations:[],
      materials: {},
      fetching: false
    }
  },
  computed: {
    // 计算属性的 getter
    isAdd: function() {
      return this.modalTitle === '新增'
    },
    isUpdate: function() {
      return this.modalTitle === '更新'
    },
    isCheckRecord: function() {
      return this.record.recordType === 2
    },
    isCheckRepairRecord: function() {
      return this.record.recordType === 4
    },
    isOperationRecord: function() {
      return this.record.recordType === 1
    },
    isOperationRepairRecord: function() {
      return this.record.recordType === 3
    },
    currentCirculationNum: function() {
      if (
        R.type(this.currentTask.productionNum) === 'Number' &&
        R.type(this.currentTask.abandonNum) === 'Number'
      ) {
        return (
          this.currentTask.productionNum -
          this.currentTask.abandonNum -
          this.record.abandonCount
        )
      }
      return 0
    },
    initCirculationNum: function() {
      if (
        this.isAdd &&
        R.type(this.currentTask.productionNum) === 'Number' &&
        R.type(this.currentTask.abandonNum) === 'Number'
      ) {
        return this.currentTask.productionNum - this.currentTask.abandonNum
      }
      if (this.isUpdate) {
        return this.recordForm.checkCount + this.recordForm.abandonCount
      }
      return 0
    },

  },
  created() {
    this.getTaskById(this.record.taskId)
    this.getAllUsers()
    this.getAllOperations()
    this.handleGetDefeatParameter()
    this.handleGetBottleneckOperationParameter();
  },
  methods: {
    getTaskById(id) {
      getTaskById(id).then(res => {
        if (res.data) {
          this.currentTask = res.data
        }
      })
    },
    getAllUsers() {
      getAllUsers(this.userQueryParams).then(res => {
        if (res.data && res.data.accountList) {
          if (R.isEmpty(this.userQueryParams)) {
            this.allUserList = R.clone(res.data.accountList)
          }
          this.currentUserList = R.clone(res.data.accountList)
        }
      })
    },
    getAllOperations() {
      getAllOperations(this.operationQueryParams).then(res => {
        if (res.data.operationList) {
          if (R.isEmpty(this.operationQueryParams)) {
            this.allOperationList = R.clone(res.data.operationList)
          }
          this.currentOperationList = R.clone(res.data.operationList)
        }
      })
    },
    handleGetDefeatParameter() {
      getAllParametersByParentCode('RT_QXYY').then(res => {
        if (res.data) {
          this.allDefeatReasons = res.data
        }
      })
    },
    handleGetBottleneckOperationParameter() {
      getBottleneckParameter().then(res => {
        if (res.data) {
          this.bottleneckOperations = res.data
        }
      })
    },
    handleUserSelected(value) {
      this.operationQueryParams.userId = 'eq:' + value
      this.getAllOperations()
    },
    handleOperationSelected(value) {
      this.userQueryParams.operationId = 'eq:' + value
      this.getAllUsers()
    },
    handleReset() {
      this.$refs.ruleForm.resetFields()
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            console.log('this')
            this.addRecord(this.record)
          } else if (this.isUpdate) {
            this.updateRecord(this.record)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleRecordAssembleModalCanceled() {
      this.loading = false
      this.assembleRecordEditVisible = false
    },
    handleRecordAssembleModalConfirmed() {
      this.assembleRecordEditVisible = false
      addRecordByTask(this.record.taskId, this.record)
        .then(res => {
          if (res.data.id) {
            this.$emit('confirm', this.record)
          } else {
            this.$message.error('新增记录失败，请重试！')
          }
        })
        .catch(
          // 记录失败原因
          reason => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
      this.$message.success('任务装配成功')
    },
    onRadioChange(e) {
      this.$refs.ruleForm.clearValidate()
      if (e.target.value === 3 || e.target.value === 4) {
        this.record.checkCount = null
        this.record.actualCheckCount = null
      }
      if (e.target.value === 1 || e.target.value === 2) {
        this.record.checkCount = this.recordForm.checkCount
        this.record.actualCheckCount = this.recordForm.actualCheckCount
      }
      // role.name: like:检验员
      if (e.target.value === 2) {
        this.userQueryParams = {
          'role.name': 'eq:检验员'
        }
        this.getAllUsers()
      }
    },

    updateRecord(recordFormData) {
      this.loading = true
      if (this.isCheckRecord) {
        recordFormData.checkCount =
          this.initCirculationNum - recordFormData.abandonCount
        recordFormData.actualCheckCount = 0
      }
      updateRecord(recordFormData.id, recordFormData)
        .then(res => {
          if (res.data.id) {
            const successMessage = '更新记录成功！'
            const failMessage = '更新记录失败，请重试！'
            this.$emit('confirm')
            this.$message.success(successMessage)
          } else {
            this.$message.error(failMessage)
          }
        })
        .catch(
          // 记录失败原因
          reason => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    addRecord(recordFormData) {
      this.loading = true
      const submitData = R.clone(recordFormData)
      this.submitData = submitData
      submitData.id = null
      if (this.isCheckRecord) {
        submitData.checkCount = this.currentCirculationNum
        submitData.actualCheckCount = recordFormData.circulationNum
        if (
          recordFormData.circulationNum !== this.currentCirculationNum &&
          !this.isCheckAssembleOperation(submitData.operationId)
        ) {
          this.$confirm({
            title: '任务分批确认',
            centered: true,
            content:
              '输入的流转数量与实际流转数量不一致，是否对当前任务' +
              recordFormData.taskCode +
              '进行分批？' +
              '(待分批数量:' +
              (submitData.checkCount - submitData.circulationNum) +
              ')',
            onOk: () => {
              const batchNum = submitData.checkCount - submitData.circulationNum
              addRecordAndBatchTask(submitData.taskId, batchNum, submitData)
                .then(res => {
                  this.$message.success('任务分批成功')
                  this.loading = false
                  console.log(submitData)
                  if (
                    submitData.abandonCount > 0 ||
                    submitData.repairedCount > 0 ||
                    submitData.outOfToleranceCount > 0
                  ) {
                    this.handleShowPrint(res.data)
                  }
                })
                .catch(reason => {
                  this.loading = false
                  this.$message.error('任务分批失败，请确认后重试')
                  console.log('处理失败的 promise (' + reason + ')')
                })
            },
            okText: '分批',
            cancelText: '取消',
            onCancel: () => {
              this.loading = false
            }
          })
          return
        }
      }
      if (this.isAssembleOperation(submitData.operationId)) {
        this.assembleForm.userId = submitData.userId
        this.assembleForm.taskCode = submitData.taskCode
        this.assembleForm.planId = this.currentTask.planId
        this.assembleRecordEditVisible = true
        return
      }

      if (submitData.operationId === 263 && this.taskFinishContinute) {
        this.handleCheckPartsTask()
        return
      }

      addRecordByTask(submitData.taskId, submitData)
        .then(res => {
          if (res.data.id) {
            this.recordId = res.data.id
            if (
              submitData.abandonCount > 0 ||
              submitData.repairedCount > 0 ||
              submitData.outOfToleranceCount > 0
            ) {
              this.handleShowPrint(res.data)
            } else {
              this.$emit('confirm', submitData)
            }
            this.loading = false
          } else {
            this.loading = false
            this.$message.error('新增记录失败，请重试！')
          }
        })
        .catch(
          // 记录失败原因
          reason => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    filterUser(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    filterReason(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    filterOperation(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    isAssembleOperation(operationId) {
      var selectOperation = this.allOperationList.filter(operation => {
        return operation.id === operationId
      })
      if (
        selectOperation !== null &&
        selectOperation[0].operationName === '装配'
      ) {
        return true
      }
      return false
    },
    isCheckAssembleOperation(operationId) {
      var selectOperation = this.allOperationList.filter(operation => {
        return operation.id === operationId
      })
      if (
        selectOperation !== null &&
        selectOperation[0].operationName === '检验装配'
      ) {
        return true
      }
      return false
    },
    checkOperationVaild() {
      this.checkLoading = true
      this.operationVaild = false
      getOperationCheck(this.record.taskId, {
        recordType: this.record.recordType,
        operationId: this.record.operationId
      })
        .then(res => {
          if (res.data.validateResult) {
            this.operationVaild = true
            this.currentOperationList.forEach(item => {
              if (item.id === this.record.operationId) {
                this.operationName = item.operationName
              }
            })
          } else {
            this.record.operationId = null
            this.operationName = null
            this.$message.error(res.data.failMessage)
          }
        })
        .finally(() => {
          this.checkLoading = false
        })

      if (this.record.operationId === 263) {
        getLastestCheckOperations(this.record.taskId).then(res => {
          console.log(res)
          this.checkNextOperationName = res.data.nextOperationName
        })
      }
      if (this.record.operationId !== 313) {
        this.record.actualUseMaterial = []
        this.actualUseMaterial = []
      }
    },
    handleCheckPartsTask() {
      console.log(this.recordForm)
      getPartsTaskByPlan(this.recordForm.planId).then(res => {
        if (res.data.length) {
          this.taskFinishUserId = this.record.userId
          this.taskFinishPlanId = this.recordForm.planId
          console.log('this.recordForm.planId', this.recordForm.planId)
          this.taskFinishModalVisible = true
        } else {
          addRecordByTask(this.record.taskId, this.record)
            .then(res => {
              if (res.data.id) {
                this.$emit('confirm', this.record)
              } else {
                this.$message.error('新增记录失败，请重试！')
              }
            })
            .catch(
              // 记录失败原因
              reason => {
                this.loading = false
                console.log('处理失败的 promise (' + reason + ')')
              }
            )
        }
      })
    },
    finishPartsTasksCancel() {
      this.loading = false
      this.taskFinishModalVisible = false
    },
    finishPartsTasksConfirm() {
      this.taskFinishModalVisible = false
      addRecordByTask(this.record.taskId, this.record)
        .then(res => {
          if (res.data.id) {
            this.$emit('confirm', this.record)
          } else {
            this.$message.error('新增记录失败，请重试！')
          }
        })
        .catch(
          // 记录失败原因
          reason => {
            this.loading = false
            console.log('处理失败的 promise (' + reason + ')')
          }
        )
    },
    finishPartsTaskIgnore() {
      this.taskFinishContinute = false
      this.handleConfirm()
    },
    handleShowPrint(data) {
      this.printVisible = true
      this.cradData = data
    },
    handleCardConfirm() {
      this.printVisible = false
      this.$emit('confirm', this.submitData)
    },
    handleCardCancel() {
      this.printVisible = false
      this.$emit('confirm', this.submitData)
    },
    handleJump(type) {
      let id = ''
      switch (type) {
        case 'rework':
          id = this.cradData.repairReceiptNumber
          break
        case 'overTolerance':
          id = this.cradData.ootReceiptNumber
          break
        case 'scrap':
          id = this.cradData.abandonReceiptNumber
          break
      }
      const route = this.$router.resolve({
        path: `/${type}-card`,
        query: {
          ids: id
        }
      })
      window.open(route.href, '_blank')
      this.$emit('confirm', submitData)
    },
    fetchMaterialByCode(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.materials = {}
      this.fetching = true
      if (value) {
        getMaterialByMaterialCode(value).then(result => {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return
          }
          this.materials = result.data
          this.fetching = false
        })
      } else {
        getAllMaterialByMaterialCode().then(result => {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return
          }
          this.materials = result.data
          this.fetching = false
        })
      }
    },
    handleMaterialNameChange(id, value) {
      console.log(this.actualUseMaterial)
      const _actualUseMaterial = []
      this.materials.map(val => {
        if (this.actualUseMaterial.indexOf(val.id) !== -1) {
          _actualUseMaterial.push(val)
        }
      })
      this.record.actualUseMaterial = _actualUseMaterial
      console.log(this.record.actualUseMaterial)
    },
    handleFetchALlMaterial() {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.materials = {}
      this.fetching = true
      getAllMaterialByMaterialCode().then(result => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        this.materials = result.data
        this.fetching = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-form-item {
  margin-bottom: 2px;
}
.modal-container {
  height: 400px;
}
.card-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    margin-bottom: 6px;
  }
}
</style>
