var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 19 } }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      icon: "el-icon-circle-plus-outline",
                    },
                    on: { click: _vm.handleAddOrder },
                  },
                  [_vm._v("新增订单")]
                ),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 5 } }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple fixed-position" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", icon: "el-icon-download" },
                    on: { click: _vm.handleDownloadTemplate },
                  },
                  [_vm._v("点击下载产品导入模板")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "orderTable",
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: {
            "element-loading-text": "加载中...",
            "row-key": _vm.getRowKey,
            border: "",
            data: _vm.orderList,
            "show-overflow-tooltip": true,
            "max-height": "700",
            "header-cell-class-name": _vm.handleHeaderClass,
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            "sort-change": _vm.handleSortChange,
            "filter-change": _vm.onFilterChange,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "55",
              align: "center",
              "reserve-selection": true,
            },
          }),
          _c("el-table-column", {
            attrs: { type: "expand", fixed: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-form",
                      {
                        staticClass: "order-table-expand",
                        attrs: { "label-position": "center", inline: "" },
                      },
                      [
                        _c("el-form-item", { attrs: { label: "负责人：" } }, [
                          _c("span", [
                            _c("b", [_vm._v(_vm._s(scope.row.charger))]),
                          ]),
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "合同文件：" } },
                          [
                            scope.row.fileName
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "file-link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDownloadFile(
                                              scope.row.id,
                                              scope.row.fileName
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.fileName))]
                                    ),
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "您确定要删除合同文件？",
                                          "ok-text": "确定",
                                          "cancel-text": "取消",
                                        },
                                        on: {
                                          confirm: function ($event) {
                                            return _vm.handleDeleteContractFile(
                                              scope.row.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          { staticClass: "filename-delete" },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "delete" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "c-upload",
                                  {
                                    attrs: {
                                      name: "file",
                                      accept: ".pdf",
                                      action:
                                        _vm.uploadUrl +
                                        "orders/" +
                                        scope.row.id +
                                        "/file",
                                    },
                                    on: { change: _vm.handleFileChange },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "file-link",
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "upload" },
                                        }),
                                        _vm._v(" 上传合同文件 "),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户合同编码：" } },
                          [
                            _c("span", [
                              _c("b", [
                                _vm._v(_vm._s(scope.row.clientContractCode)),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户采购订单编码：" } },
                          [
                            _c("span", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(scope.row.clientPurchaseOrderCode)
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户订单编码：" } },
                          [
                            _c("span", [
                              _c("b", [
                                _vm._v(_vm._s(scope.row.clientOrderCode)),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户项目编码：" } },
                          [
                            _c("span", [
                              _c("b", [
                                _vm._v(_vm._s(scope.row.clientProjectCode)),
                              ]),
                            ]),
                          ]
                        ),
                        _c("el-form-item", { attrs: { label: "预付额：" } }, [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.advancePayment)),
                          ]),
                        ]),
                        _c("el-form-item", { attrs: { label: "尾款金额：" } }, [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.balancePayment)),
                          ]),
                        ]),
                        _c("el-form-item", { attrs: { label: "欠款金额：" } }, [
                          _c("span", [_vm._v(_vm._s(scope.row.debtPayment))]),
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注：" } },
                          [
                            _c("a-input", {
                              attrs: { type: "textarea", disabled: "" },
                              model: {
                                value: scope.row.comment,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "comment", $$v)
                                },
                                expression: "scope.row.comment",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              width: "70",
              align: "center",
              index: _vm.indexMethod,
            },
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              label: "签订日期",
              prop: "signTime",
              "column-key": "signTime",
              width: "150",
              align: "center",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "date",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(Date.getDayTime(scope.row.signTime)))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              label: "合同交货日期",
              prop: "deadline",
              "column-key": "deadline",
              width: "190",
              align: "center",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(Date.getDayTime(scope.row.deadline)))]
                },
              },
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "date",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              label: "客户编号",
              prop: "clientCode",
              "column-key": "clientCode",
              width: "130",
              align: "center",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              label: "客户名称",
              prop: "client",
              "column-key": "client",
              width: "130",
              align: "center",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              label: "客户简称",
              prop: "clientNickName",
              "column-key": "clientNickName",
              width: "130",
              align: "center",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "订单编码",
              prop: "orderCode",
              "column-key": "orderCode",
              width: "150",
              align: "center",
              sortable: "custom",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
              {
                key: "default",
                fn: function (scope) {
                  return _c(
                    "el-link",
                    {
                      attrs: { type: "primary", title: "点击查看计划详情" },
                      on: {
                        click: function ($event) {
                          return _vm.jumpToDashboardPage(scope.row.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(scope.row.orderCode))]
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "订单产品项数",
              prop: "itemCount",
              "column-key": "itemCount",
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.column.label))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "所有计划是否下达",
              prop: "isAllPlanExisted",
              "column-key": "isAllPlanExisted",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.isAllPlanExisted
                      ? _c("span", [_vm._v("已下达")])
                      : _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: "确定下达该订单下所有计划？",
                              placement: "top",
                            },
                            on: {
                              onConfirm: function ($event) {
                                return _vm.handleMakeOrderPlans(scope)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "primary",
                                  size: "mini",
                                  loading: scope.row.isButtonLoading,
                                  round: "",
                                },
                                slot: "reference",
                              },
                              [_vm._v("请点击下达所有计划")]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "订单状态",
              prop: "statusText",
              "column-key": "statusText",
              width: "110",
              align: "center",
              filters: [
                { text: "未开始", value: 1 },
                { text: "进行中", value: 2 },
                { text: "即将超期", value: 5 },
                { text: "超期进行中", value: 4 },
                { text: "已完成", value: 3 },
                { text: "暂停中", value: 9 },
                { text: "已取消", value: 8 },
              ],
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          effect: "dark",
                          type: scope.row.statusTag.type,
                        },
                      },
                      [_vm._v(_vm._s(scope.row.statusTag.label))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              label: "总金额(无税)",
              prop: "totalMoneyTaxFree",
              "column-key": "totalMoneyTaxFree",
              width: "140",
              align: "center",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "number",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              label: "总金额(含税)",
              prop: "totalMoney",
              "column-key": "totalMoney",
              width: "140",
              align: "center",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "number",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              label: "累计收款金额",
              prop: "totalProceeds",
              "column-key": "totalProceeds",
              width: "140",
              align: "center",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "number",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              label: "累计开票金额",
              prop: "totalInvoiceAmount",
              "column-key": "totalInvoiceAmount",
              width: "140",
              align: "center",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "number",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              label: "累计发票号",
              prop: "totalInvoiceNum",
              "column-key": "totalInvoiceNum",
              width: "130",
              align: "center",
              filters: [],
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.column.label) + " "),
                    _c("SearchFilterDropdown", {
                      attrs: {
                        label: scope.column.label,
                        "column-key": scope.column.property,
                        "search-type": "text",
                      },
                      on: { search: _vm.handleColumnSearch },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "结算状态",
              prop: "clearingStatus",
              "column-key": "clearingStatus",
              width: "110",
              align: "center",
              filters: [
                { text: "待结算", value: 1 },
                { text: "部分结算", value: 2 },
                { text: "已结清", value: 5 },
              ],
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.clearingStatus === 1
                      ? _c(
                          "el-tag",
                          { attrs: { effect: "dark", type: "processing" } },
                          [_vm._v("待结算")]
                        )
                      : _vm._e(),
                    scope.row.clearingStatus === 2
                      ? _c(
                          "el-tag",
                          { attrs: { effect: "dark", type: "warning" } },
                          [_vm._v("部分结算")]
                        )
                      : _vm._e(),
                    scope.row.clearingStatus === 3
                      ? _c(
                          "el-tag",
                          { attrs: { effect: "dark", type: "success" } },
                          [_vm._v("已结清")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", fixed: "right", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-dropdown",
                      {
                        attrs: { trigger: "click" },
                        on: {
                          command: function (value) {
                            _vm.handleCommand(value, scope)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _vm._v(" 操作 "),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  icon: "el-icon-edit",
                                  command: "update",
                                },
                              },
                              [_vm._v("更新订单信息")]
                            ),
                            scope.row.status !== 3 &&
                            scope.row.status !== 6 &&
                            scope.row.status !== 8 &&
                            scope.row.status !== 9
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      icon: "el-icon-close",
                                      command: "cancel",
                                    },
                                  },
                                  [_vm._v("取消订单")]
                                )
                              : _vm._e(),
                            scope.row.status === 2 ||
                            scope.row.status === 4 ||
                            scope.row.status === 5
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      icon: "el-icon-video-pause",
                                      command: "suspend",
                                    },
                                  },
                                  [_vm._v("暂停订单")]
                                )
                              : _vm._e(),
                            scope.row.status === 9
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      icon: "el-icon-video-play",
                                      command: "resume",
                                    },
                                  },
                                  [_vm._v("恢复订单")]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  icon: "el-icon-delete",
                                  command: "delete",
                                },
                              },
                              [_vm._v("删除订单")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "addProduct" } },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-plus-outline",
                                }),
                                _vm._v("新增订单产品 "),
                              ]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "updatePayment" } },
                              [
                                _c("i", { staticClass: "el-icon-edit" }),
                                _vm._v("更新支付信息 "),
                              ]
                            ),
                            _c(
                              "el-dropdown-item",
                              [
                                _c("Upload", {
                                  attrs: {
                                    action:
                                      _vm.uploadUrl +
                                      "orders/" +
                                      scope.row.id +
                                      "/products/imports",
                                    accept: ".xlsx",
                                    desc: "Excel",
                                  },
                                  on: { refreshTable: _vm.refreshTable },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dropdown",
        {
          staticClass: "export-order",
          on: { command: _vm.handleExportOrderCommand },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.exportLoading,
                size: "medium",
                type: "primary",
                icon: "el-icon-download",
              },
            },
            [_vm._v("导出订单")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "SELECTED" } }, [
                _vm._v("导出当前已选订单"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "PAGE" } }, [
                _vm._v("导出当前页订单"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "FILTERED" } }, [
                _vm._v("导出当前筛选订单"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "ALL" } }, [
                _vm._v("导出所有订单"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.editModalVisible
        ? _c("OrderEdit", {
            attrs: {
              "modal-title": _vm.currentEditType,
              "order-form": _vm.currentOrder,
            },
            on: {
              cancel: _vm.handleOrderModalCanceled,
              confirm: _vm.handleOrderModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.paymentModalVisible
        ? _c("PaymentEdit", {
            attrs: { "modal-title": "更新", "order-form": _vm.currentOrder },
            on: {
              cancel: _vm.handlePaymentModalCanceled,
              confirm: _vm.handlePaymentModalConfirmed,
            },
          })
        : _vm._e(),
      _vm.addProductModalVisible
        ? _c("ProductEdit", {
            attrs: {
              "modal-title": "新增订单",
              "order-id": _vm.currentOrderId,
              "product-form": _vm.currentProduct,
            },
            on: {
              cancel: _vm.handleProductModalCanceled,
              confirm: _vm.handleProductModalConfirmed,
            },
          })
        : _vm._e(),
      _c("Pagination", {
        ref: "pagination",
        attrs: { total: _vm.total },
        on: { getData: _vm.getOrders },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }