<template>
  <div>
    <a-modal v-drag-modal class="modal-container" :visible="true" :title="modalTitle" :mask-closable="false"
      width="600px" @cancel="handleCancel">
      <template slot="footer">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" :loading="loading" @click="handleConfirm">确定</a-button>
      </template>
      <a-form-model ref="form" :rules="rules" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <template v-if="type === 'out'">
          <a-form-model-item label="出库类型" prop="takeOutType">
            <a-radio-group v-model="form.takeOutType" style="width: 400px; fontweight: 400">
              <a-radio :value="0">普通出库</a-radio>
              <a-radio :value="1">返修出库</a-radio>
              <a-radio :value="2">其他出库</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </template>
        <a-form-model-item v-if="type === 'out'" label="物流单号" prop="trackingNumber">
          <a-input v-model="form.trackingNumber"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="type === 'out'" label="模板" prop="template">
          <a-radio-group v-model="template" style="width: 400px; fontweight: 400">
            <a-radio :value="1">模板1</a-radio>
            <a-radio :value="2">模板2（渝江）</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <template v-if="type === 'save'">
          <a-form-model-item label="类型">
            <a-radio-group v-model="cardType" style="width: 400px; fontweight: 400">
              <a-radio :value="1" v-if="warehouseType === 4">废品入库单</a-radio>
              <a-radio :value="0" v-else>普通入库单</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </template>
        <a-form-model-item label="备注" prop="comment">
          <a-textarea v-model.trim="form.comment" :placeholder="placeholder" :max-length="500"
            :auto-size="{ minRows: 3, maxRows: 5 }" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal :visible="modalVisible" @cancel="onCancel">
      <p style="margin: 20px;">当前出库的任务订单客户合同号不唯一，是否允许同一出库单出库?</p>
      <div slot="footer">
        <a-button @click="onCancel">取消</a-button>
        <a-button :loading="loading" @click="onNotPermit" type="primary">不允许</a-button>
        <a-button :loading="loading" @click="onPermit" type="primary">允许</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {
  putOutRecords,
  putInRecords,
  checkOutRecords,
  createAbandonRecord
} from '@/api/inventory'
export default {
  props: {
    type: String,
    recordsData: Array,
    warehouseType: String | Number
  },
  data() {
    return {
      placeholder: this.type === 'save' ? '入库单备注' : '出库单备注',
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 10,
        offset: 1
      },
      template: 1,
      cardType: 0,
      form: {
        takeOutType: 0,
        withParts: 0,
        trackingNumber: '',
        comment: ''
      },
      rules: {
        takeOutType: [
          {
            required: true,
            message: '请选择出库类型',
            trigger: 'change'
          }
        ],
        withParts: [
          {
            required: true,
            message: '请选择分件类型',
            trigger: 'change'
          }
        ]
      },
      modalVisible: false,
      loading: false
    }
  },
  created() {
    if (this.warehouseType === 4) {
      this.cardType = 1
    }
  },
  computed: {
    modalTitle() {
      console.log(this.warehouseType)
      if (this.type === 'save') {
        if (this.warehouseType === 4) {
          return '废品入库交接单开单信息'
        } else {
          return '入库单开单信息'
        }
      } else {
        return '出库单开单信息'
      }
    }
  },
  methods: {
    initData() {
      this.form.takeOutType = 0
      this.form.withParts = 0
      this.form.comment = ''
    },
    handleCancel() {
      this.initData()
      this.$emit('cancel')
    },
    handleConfirm() {
      this.loading = true
      this.$refs.form.validate(valid => {
        const that = this
        if (valid) {
          if (this.type === 'save') {
            const params = {
              inOutRecordIds: this.recordsData,
              putInComment: this.form.comment
            }
            if (this.cardType === 1) {
              createAbandonRecord(params).then(res => {
                if (res.data) {
                  this.$confirm({
                    title: '废品入库单生成成功',
                    content: '废品入库单生成成功，是否需要打印',
                    okText: '打印',
                    onOk() {
                      const route = that.$router.resolve({
                        path: '/waste-receipt-card',
                        query: {
                          receiptNumber: res.data
                        }
                      })
                      window.open(route.href, '_blank')
                      that.$emit('confirm')
                      that.initData()
                    },
                    cancelText: '暂不打印',
                    onCancel() {
                      that.$emit('confirm')
                      that.initData()
                    }
                  })
                }
              }).catch(
                reason => {
                  this.loading = false
                  console.log('处理失败的 promise (' + reason + ')')
                }
              )
            } else {
              putInRecords(params).then(res => {
                if (res.data) {
                  this.$confirm({
                    title: '单据生成成功',
                    content: '入库单生成成功，是否需要打印',
                    okText: '打印',
                    onOk() {
                      const route = that.$router.resolve({
                        path: '/save-inventory-card',
                        query: {
                          receiptNumber: res.data
                        }
                      })
                      window.open(route.href, '_blank')
                      that.$emit('confirm')
                    },
                    cancelText: '暂不打印',
                    onCancel() {
                      that.$emit('confirm')
                    }
                  })
                }
                that.initData()
              })
            }
          } else {
            const params = {
              inOutRecordIds: this.recordsData,
              takeOutType: this.form.takeOutType,
              withParts: this.form.withParts === 1,
              takeOutComment: this.form.comment,
              trackingNumber: this.form.trackingNumber
            }
            checkOutRecords({ outRecordIds: this.recordsData.join(',') }).then(
              res => {
                if (res.data > 1) {
                  this.loading = false
                  this.modalVisible = true
                } else {
                  putOutRecords(params).then(res => {
                    if (res.data) {
                      this.$confirm({
                        title: '单据生成成功',
                        content: '出库单生成成功，是否需要打印',
                        okText: '打印',
                        onOk() {
                          const route = that.$router.resolve({
                            path:
                              that.template === 1
                                ? '/out-inventory-card'
                                : '/new-out-inventory-card',
                            query: {
                              receiptNumber: res.data.join(',')
                            }
                          })
                          window.open(route.href, '_blank')
                          that.$emit('confirm')
                          that.initData()
                        },
                        cancelText: '暂不打印',
                        onCancel() {
                          that.$emit('confirm')
                          that.initData()
                        }
                      })
                    }
                  })
                }
              }
            ).catch(
              reason => {
                this.loading = false
                console.log('处理失败的 promise (' + reason + ')')
              }
            )
          }
        } else {
          console.log('error submit!!', valid)
          this.loading = false
          return false
        }
      })
    },
    onPermit() {
      this.loading = true
      const that = this
      const params = {
        inOutRecordIds: this.recordsData,
        takeOutType: this.form.takeOutType,
        withParts: true,
        takeOutComment: this.form.comment,
        trackingNumber: this.form.trackingNumber
      }
      putOutRecords(params).then(res => {
        if (res.data) {
          that.$confirm({
            title: '单据生成成功',
            content: '出库单生成成功，是否需要打印',
            okText: '打印',
            onOk() {
              const route = that.$router.resolve({
                path:
                  that.template === 1
                    ? '/out-inventory-card'
                    : '/new-out-inventory-card',
                query: {
                  receiptNumber: res.data.join(',')
                }
              })
              window.open(route.href, '_blank')
              that.$emit('confirm')
              that.initData()
            },
            cancelText: '暂不打印',
            onCancel() {
              that.$emit('confirm')
              that.initData()
            }
          })
        }
        this.modalVisible = false
      }).catch(
        reason => {
          this.loading = false
          console.log('处理失败的 promise (' + reason + ')')
        }
      )
    },
    onNotPermit() {
      this.loading = true
      const that = this
      const params = {
        inOutRecordIds: this.recordsData,
        takeOutType: this.form.takeOutType,
        withParts: false,
        takeOutComment: this.form.comment,
        trackingNumber: this.form.trackingNumber
      }
      putOutRecords(params).then(res => {
        if (res.data) {
          that.$confirm({
            title: '单据生成成功',
            content: '出库单生成成功，是否需要打印',
            okText: '打印',
            onOk() {
              const route = that.$router.resolve({
                path:
                  that.template === 1
                    ? '/out-inventory-card'
                    : '/new-out-inventory-card',
                query: {
                  receiptNumber: res.data.join(',')
                }
              })
              window.open(route.href, '_blank')
              that.$emit('confirm')
              that.initData()
            },
            cancelText: '暂不打印',
            onCancel() {
              that.$emit('confirm')
              that.initData()
            }
          }).catch(
            reason => {
              this.loading = false
              console.log('处理失败的 promise (' + reason + ')')
            }
          )
        }
        this.modalVisible = false
      })
    },
    onCancel() {
      this.modalVisible = false
    }
  }
}
</script>

<style scoped></style>
