<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="19">
        <div class="grid-content bg-purple">
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleAddOrder">新增订单</el-button>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple fixed-position">
          <el-button type="text" icon="el-icon-download" @click="handleDownloadTemplate">点击下载产品导入模板</el-button>
        </div>
      </el-col>
    </el-row>
    <el-table
      ref="orderTable"
      v-loading="loading"
      element-loading-text="加载中..."
      :row-key="getRowKey"
      border
      :data="orderList"
      :show-overflow-tooltip="true"
      style="width: 100%;margin-top: 30px;"
      max-height="700"
      :header-cell-class-name="handleHeaderClass"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
      @filter-change="onFilterChange"
    >
      <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
      <el-table-column type="expand" fixed="left">
        <template slot-scope="scope">
          <el-form label-position="center" inline class="order-table-expand">
            <el-form-item label="负责人：">
              <span>
                <b>{{ scope.row.charger }}</b>
              </span>
            </el-form-item>
            <el-form-item label="合同文件：">
              <span v-if="scope.row.fileName">
                <span class="file-link" @click="handleDownloadFile(scope.row.id, scope.row.fileName)">{{ scope.row.fileName }}</span>
                <a-popconfirm title="您确定要删除合同文件？" ok-text="确定" cancel-text="取消" @confirm="handleDeleteContractFile(scope.row.id)">
                  <a class="filename-delete">
                    <a-icon type="delete" />
                  </a>
                </a-popconfirm>
              </span>
              <c-upload v-else name="file" accept=".pdf" :action="`${uploadUrl}orders/${scope.row.id}/file`" @change="handleFileChange">
                <a slot="content" class="file-link">
                  <a-icon type="upload" />&nbsp;上传合同文件
                </a>
              </c-upload>
            </el-form-item>
            <el-form-item label="客户合同编码：">
              <span>
                <b>{{ scope.row.clientContractCode }}</b>
              </span>
            </el-form-item>
            <el-form-item label="客户采购订单编码：">
              <span>
                <b>{{ scope.row.clientPurchaseOrderCode }}</b>
              </span>
            </el-form-item>
            <el-form-item label="客户订单编码：">
              <span>
                <b>{{ scope.row.clientOrderCode }}</b>
              </span>
            </el-form-item>
            <el-form-item label="客户项目编码：">
              <span>
                <b>{{ scope.row.clientProjectCode }}</b>
              </span>
            </el-form-item>
            <el-form-item label="预付额：">
              <span>{{ scope.row.advancePayment }}</span>
            </el-form-item>
            <el-form-item label="尾款金额：">
              <span>{{ scope.row.balancePayment }}</span>
            </el-form-item>
            <el-form-item label="欠款金额：">
              <span>{{ scope.row.debtPayment }}</span>
            </el-form-item>
            <el-form-item label="备注：">
              <a-input v-model="scope.row.comment" type="textarea" disabled />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="序号" type="index" width="70" align="center" :index="indexMethod" />
      <el-table-column sortable="custom" label="签订日期" prop="signTime" column-key="signTime" width="150" align="center" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="date"
            @search="handleColumnSearch"
          />
        </template>
        <template slot-scope="scope">{{ Date.getDayTime(scope.row.signTime) }}</template>
      </el-table-column>
      <el-table-column sortable="custom" label="合同交货日期" prop="deadline" column-key="deadline" width="190" align="center" :filters="[]">
        <template slot-scope="scope">{{ Date.getDayTime(scope.row.deadline) }}</template>
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="date"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column sortable="custom" label="客户编号" prop="clientCode" column-key="clientCode" width="130" align="center" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column sortable="custom" label="客户名称" prop="client" column-key="client" width="130" align="center" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column sortable="custom" label="客户简称" prop="clientNickName" column-key="clientNickName" width="130" align="center" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>

      <el-table-column label="订单编码" prop="orderCode" column-key="orderCode" width="150" align="center" sortable="custom" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
        <el-link slot-scope="scope" type="primary" title="点击查看计划详情" @click="jumpToDashboardPage(scope.row.id)">{{ scope.row.orderCode }}</el-link>
      </el-table-column>
      <el-table-column label="订单产品项数" prop="itemCount" column-key="itemCount" width="150" align="center">
        <template #header="scope">{{ scope.column.label }}</template>
      </el-table-column>
      <el-table-column align="center" label="所有计划是否下达" prop="isAllPlanExisted" column-key="isAllPlanExisted" width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.isAllPlanExisted">已下达</span>
          <el-popconfirm v-else title="确定下达该订单下所有计划？" placement="top" @onConfirm="handleMakeOrderPlans(scope)">
            <el-button slot="reference" type="primary" size="mini" :loading="scope.row.isButtonLoading" round>请点击下达所有计划</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column
        label="订单状态"
        prop="statusText"
        column-key="statusText"
        width="110"
        align="center"
        :filters="[{ text: '未开始', value: 1 },{ text: '进行中', value: 2 },{ text: '即将超期', value: 5 },{ text: '超期进行中', value: 4 },{ text: '已完成', value: 3 },
                   { text: '暂停中', value: 9 },{ text: '已取消', value: 8 }]"
      >
        <template slot-scope="scope">
          <el-tag effect="dark" :type="scope.row.statusTag.type">{{ scope.row.statusTag.label }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        sortable="custom"
        label="总金额(无税)"
        prop="totalMoneyTaxFree"
        column-key="totalMoneyTaxFree"
        width="140"
        align="center"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="number"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column sortable="custom" label="总金额(含税)" prop="totalMoney" column-key="totalMoney" width="140" align="center" :filters="[]">
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="number"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        label="累计收款金额"
        prop="totalProceeds"
        column-key="totalProceeds"
        width="140"
        align="center"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="number"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        label="累计开票金额"
        prop="totalInvoiceAmount"
        column-key="totalInvoiceAmount"
        width="140"
        align="center"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="number"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        label="累计发票号"
        prop="totalInvoiceNum"
        column-key="totalInvoiceNum"
        width="130"
        align="center"
        :filters="[]"
      >
        <template #header="scope">
          {{ scope.column.label }}
          <SearchFilterDropdown
            :label="scope.column.label"
            :column-key="scope.column.property"
            search-type="text"
            @search="handleColumnSearch"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="结算状态"
        prop="clearingStatus"
        column-key="clearingStatus"
        width="110"
        align="center"
        :filters="[{ text: '待结算', value: 1 },{ text: '部分结算', value: 2 },{ text: '已结清', value: 5 }]"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.clearingStatus === 1" effect="dark" type="processing">待结算</el-tag>
          <el-tag v-if="scope.row.clearingStatus === 2" effect="dark" type="warning">部分结算</el-tag>
          <el-tag v-if="scope.row.clearingStatus === 3" effect="dark" type="success">已结清</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-dropdown trigger="click" @command="((value) => { handleCommand(value, scope) })">
            <span class="el-dropdown-link">
              操作
              <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-edit" command="update">更新订单信息</el-dropdown-item>
              <el-dropdown-item
                v-if="scope.row.status!==3&&scope.row.status!==6&&scope.row.status!==8&&scope.row.status!==9"
                icon="el-icon-close"
                command="cancel"
              >取消订单</el-dropdown-item>
              <el-dropdown-item
                v-if="scope.row.status===2||scope.row.status===4||scope.row.status===5"
                icon="el-icon-video-pause"
                command="suspend"
              >暂停订单</el-dropdown-item>
              <el-dropdown-item v-if="scope.row.status===9" icon="el-icon-video-play" command="resume">恢复订单</el-dropdown-item>
              <el-dropdown-item icon="el-icon-delete" command="delete">删除订单</el-dropdown-item>
              <el-dropdown-item command="addProduct">
                <i class="el-icon-circle-plus-outline" />新增订单产品
              </el-dropdown-item>
              <el-dropdown-item command="updatePayment">
                <i class="el-icon-edit" />更新支付信息
              </el-dropdown-item>
              <el-dropdown-item>
                <Upload
                  :action="`${uploadUrl}orders/${scope.row.id}/products/imports`"
                  :accept="'.xlsx'"
                  :desc="'Excel'"
                  @refreshTable="refreshTable"
                />
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-dropdown class="export-order" @command="handleExportOrderCommand">
      <el-button :loading="exportLoading" size="medium" type="primary" icon="el-icon-download">导出订单</el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="SELECTED">导出当前已选订单</el-dropdown-item>
        <el-dropdown-item command="PAGE">导出当前页订单</el-dropdown-item>
        <el-dropdown-item command="FILTERED">导出当前筛选订单</el-dropdown-item>
        <el-dropdown-item command="ALL">导出所有订单</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <OrderEdit
      v-if="editModalVisible"
      :modal-title="currentEditType"
      :order-form="currentOrder"
      @cancel="handleOrderModalCanceled"
      @confirm="handleOrderModalConfirmed"
    />
    <PaymentEdit
      v-if="paymentModalVisible"
      modal-title="更新"
      :order-form="currentOrder"
      @cancel="handlePaymentModalCanceled"
      @confirm="handlePaymentModalConfirmed"
    />
    <ProductEdit
      v-if="addProductModalVisible"
      modal-title="新增订单"
      :order-id="currentOrderId"
      :product-form="currentProduct"
      @cancel="handleProductModalCanceled"
      @confirm="handleProductModalConfirmed"
    />
    <Pagination ref="pagination" :total="total" @getData="getOrders" />
  </div>
</template>

<script>
import '@/utils/directives'
import '@/utils/dateFormat'
import * as R from 'ramda'
import { downloadItem, handleDownLoad } from '@/utils/api.request'
import { downloadTemplate } from '@/common/templateDownload'
import { formatDateTime, handleTimeZone } from '@/utils/date-format-util'
import { ltegte, ltegteDate, inFn } from '@/utils/conditionQuery'
import {
  deepClone,
  sortMap,
  getSortParams,
  removePropertyOfNull,
} from '@/utils'
import { CONSTANTS } from '@/utils/constants'
import OrderEdit from '@/components/OrderEdit'
import ProductEdit from '@/components/ProductEdit'
import PaymentEdit from '@/components/PaymentEdit'
import {
  getOrders,
  getOrderById,
  addOrder,
  updateOrder,
  deleteOrder,
  deleteOrderPdf,
  addProductByOrder,
  getPaymentByOrder,
  addPaymentByOrder,
  updatePaymentByOrder,
  exportAllOrders,
  exportOrdersByIds,
  exportFilteredOrders,
  exportCurrentPageOrders,
  makeOrderPlans,
  switchOrderStatus,
} from '@/api/order'
import Pagination from '@/components/Pagination'
import Upload from '@/components/Upload'
import SearchFilterDropdown from '@/components/SearchFilterDropdown'
import { handleFormMixin } from '@/utils/mixin'

export default {
  components: {
    Pagination,
    Upload,
    SearchFilterDropdown,
    OrderEdit,
    ProductEdit,
    PaymentEdit,
  },
  mixins: [handleFormMixin],
  data() {
    // 总金额验证规则
    const totalMoney = (rule, value, callback) => {
      if (typeof value === 'number' || !value) {
        callback()
      } else {
        if (typeof (value * 1) === 'number' && value * 1 >= 0) {
          callback()
        } else {
          callback(new Error('请输入大于等于0范围内的数字'))
        }
      }
    }
    // 数字类型验证 不可以为空
    const requireNumberValidate = (rule, value, callback) => {
      if (typeof value === 'number') {
        callback()
      } else if (
        (typeof value === 'string' && value.trim() === '') ||
        value === null ||
        value === undefined
      ) {
        callback(new Error('不可为空'))
      } else {
        if (typeof (value * 1) === 'number' && value * 1 >= 0) {
          callback()
        } else {
          callback(new Error('请输入大于等于0范围内的数字'))
        }
      }
    }
    return {
      total: 0,
      loading: true,
      exportLoading: false,
      paymentLoading: true,
      paymentDialogLoading: true,
      product: {},
      order: {},
      payment: {},
      orderList: [],
      paymentData: {},
      dialogVisible: false,
      addProduct: false,
      showPaymentDialog: false,
      handlePaymentDialog: false,
      dialogType: 'new',
      isUpdatePayment: false,
      checkStrictly: false,
      isUpdate: false,
      searchDrawer: false,
      uploadUrl: process.env.VUE_APP_BASE_API,
      signTimeLt: null,
      signTimeGt: null,
      deadlineLt: null,
      deadlineGt: null,
      currentPageSize: 0,
      currentPageNum: 0,
      currentEditType: '',
      currentOrder: {},
      editModalVisible: false,
      addProductModalVisible: false,
      paymentModalVisible: false,
      currentOrderId: null,
      currentProduct: {},
      multipleSelection: [],
      exportIds: [],
      sortByMap: new Map(),
      columnFilterParams: {},
      baseURL: process.env.VUE_APP_BASE_API,
      searchForm: {
        orderCode: '',
        clientContractCode: '',
        clientPurchaseOrderCode: '',
        client: '',
        invoiceCode: '',
        itemCountLt: '',
        itemCountGt: '',
        totalMoneyLt: '',
        totalMoneyGt: '',
        totalMoneyTaxFreeLt: '',
        totalMoneyTaxFreeGt: '',
        statusList: [],
        signTimeLt: null,
        signTimeGt: null,
        deadlineLt: null,
        deadlineGt: null,
      },
      rules: {
        orderCode: [
          {
            required: true,
            message: '请输入合同编号',
            trigger: 'change',
          },
        ],
        signTime: [
          {
            required: true,
            message: '请选择签约日期',
            trigger: 'change',
          },
        ],
        client: [
          {
            required: true,
            message: '请输入客户名称',
            trigger: 'change',
          },
        ],
        itemCount: [
          {
            required: true,
            message: '请输入订单项数',
            trigger: 'change',
          },
        ],
        status: [
          {
            required: true,
            message: '请选择订单状态',
            trigger: 'change',
          },
        ],
      },
    }
  },
  watch: {
    orderList(val) {
      val.forEach((item, i) => {
        this.multipleSelection.forEach((multItem, j) => {
          if (item.id == multItem.id) {
            this.$refs.orderTable.toggleRowSelection(item, true) // 这步操作是让  页面显示选中的数据
          }
        })
      })
    },
  },
  created() {
    const data = {
      pageNum: 1,
      pageSize: 10,
    }
    this.getOrders(data)
  },
  methods: {
    indexMethod(index) {
      return (this.currentPageNum - 1) * this.currentPageSize + (index + 1)
    },
    getRowKey(row) {
      return row.id
    },
    async getOrders(data) {
      this.loading = true
      this.currentPageSize = data.pageSize
      this.currentPageNum = data.pageNum
      const filterSortParams = this.getFilterSortParams()
      const params = Object.assign(data, filterSortParams)
      try {
        const res = await getOrders(params)
        /* 处理状态开始 */
        const obj = {
          1: {
            label: '未开始',
            type: 'info',
          },
          2: {
            label: '进行中',
            type: '',
          },
          3: {
            label: '已完成',
            type: 'success',
          },
          4: {
            label: '超期进行中',
            type: 'danger',
          },
          5: {
            label: '即将超期',
            type: 'danger',
          },
          6: {
            label: '超期完成',
            type: 'warning',
          },
          7: {
            label: '超期取消',
            type: 'info',
          },
          8: {
            label: '已取消',
            type: 'info',
          },
          9: {
            label: '暂停中',
            type: 'info',
          },
        }
        res.data.records.forEach((item) => {
          item.statusTag = obj[item.status]
        })
        /* 处理状态完成 */
        this.orderList = res.data.records
        this.total = res.data.total
        this.loading = false
      } catch (e) {
        this.loading = false
        console.log(e)
      }
    },
    handleCommand(value, scope) {
      switch (value) {
        case 'update':
          this.handleUpdateOrder(scope)
          break
        case 'delete':
          this.handleDeleteOrder(scope.row)
          break
        case 'deletePdf':
          this.handleDeleteFile(scope.row.id)
          break
        case 'showPdf':
          this.handleShowFile(scope.row.id)
          break
        case 'addProduct':
          this.handleAddProduct(scope)
          break
        case 'addPayment':
          this.handleAddPayment(scope)
          break
        case 'updatePayment':
          this.handleUpdatePayment(scope)
          break
        case 'showProducts':
          this.showProductsByOrder(scope.row.orderCode)
          break
        case 'cancel':
          this.switchOrderStatus(scope.row, 'c')
          break
        case 'suspend':
          this.switchOrderStatus(scope.row, 's')
          break
        case 'resume':
          this.switchOrderStatus(scope.row, 'r')
          break
      }
    },
    // 改变订单状态
    switchOrderStatus(row, statusCode) {
      let actionName = ''
      if (statusCode === 'c') {
        actionName = '取消'
      } else if (statusCode === 's') {
        actionName = '暂停'
      } else if (statusCode === 'r') {
        actionName = '恢复'
      }
      this.$confirm({
        content: '确认' + actionName + '订单' + row.orderCode + '?',
        onOk: () => {
          switchOrderStatus(row.id, statusCode).then(() => {
            this.$message.success(actionName + '订单成功！')
            this.$refs.pagination.currentChange(this.currentPageNum)
          })
        },
      })
    },
    // 删除订单
    handleDeleteOrder(column) {
      const orderCode = column.orderCode ? column.orderCode : ''
      this.$confirm({
        content: '确认删除订单' + orderCode + '?',
        onOk: () => {
          deleteOrder(column.id).then(() => {
            this.$message.success('删除订单成功！')
            this.$refs.pagination.currentChange(this.currentPageNum)
          })
        },
      })
    },
    signTimeLtChange(data) {
      this.signTimeLt = data
    },
    signTimeGtChange(data) {
      this.signTimeGt = data
    },
    deadlineLtChange(data) {
      this.deadlineLt = data
    },
    deadlineGtChange(data) {
      this.deadlineGt = data
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 设置列的排序为我们自定义的排序
    handleHeaderClass({ column }) {
      column.order = column.multiOrder
    },
    handleSortChange({ column }) {
      // 有些列不需要排序，提前返回
      if (column.sortable !== 'custom') {
        return
      }
      const order = column.order
      column.multiOrder = order
      const key = column.property
      this.sortByMap.set(key, order)
      const filterSortParams = this.getFilterSortParams()
      this.$refs.pagination.currentChange(1, filterSortParams)
    },
    handleSearch() {
      this.$refs.drawer.closeDrawer()
      // 处理数据
      const filterSortParams = this.getFilterSortParams()
      this.$refs.pagination.currentChange(1, filterSortParams)
    },
    handleColumnSearch(data) {
      this.columnFilterParams = R.merge(this.columnFilterParams, data)
      const filterSortParams = this.getFilterSortParams()
      this.$refs.pagination.currentChange(1, filterSortParams)
    },

    onFilterChange(object) {
      console.log(object)
      this.columnFilterParams.status = ''
      this.columnFilterParams.clearingStatus = ''
      const newObject = {}
      if (object.statusText && object.statusText.length > 0) {
        newObject.status = 'in:' + object.statusText.toString()
      }
      if (object.clearingStatus && object.clearingStatus.length > 0) {
        newObject.clearingStatus = 'in:' + object.clearingStatus.toString()
      }
      this.handleColumnSearch(newObject)
    },
    getFilterSortParams() {
      const sortParams = getSortParams(this.sortByMap)
      const filterSortParams = R.merge(sortParams, this.columnFilterParams)
      return R.reject(R.equals(''))(filterSortParams)
    },
    getFilterParams() {
      const searchForm = deepClone(this.searchForm)
      const searchData = {}
      Object.keys(searchForm).map((item) => {
        if (item.includes('Gt') || item.includes('Lt')) {
          // 处理订单项数
          if (item === 'itemCountLt') {
            if (
              ltegte({
                lte: searchForm['itemCountLt'],
                gte: searchForm['itemCountGt'],
              })
            ) {
              searchData['itemCount'] = ltegte({
                lte: searchForm['itemCountLt'],
                gte: searchForm['itemCountGt'],
              })
            }
          }
          // 处理订单总金额（含税）
          if (item === 'totalMoneyLt') {
            if (
              ltegte({
                lte: searchForm['totalMoneyLt'],
                gte: searchForm['totalMoneyGt'],
              })
            ) {
              searchData['totalMoney'] = ltegte({
                lte: searchForm['totalMoneyLt'],
                gte: searchForm['totalMoneyGt'],
              })
            }
          }
          // 处理订单总金额（无税）
          if (item === 'totalMoneyTaxFreeLt') {
            if (
              ltegte({
                lte: searchForm['totalMoneyTaxFreeLt'],
                gte: searchForm['totalMoneyTaxFreeGt'],
              })
            ) {
              searchData['totalMoneyTaxFree'] = ltegte({
                lte: searchForm['totalMoneyTaxFreeLt'],
                gte: searchForm['totalMoneyTaxFreeGt'],
              })
            }
          }
          // 处理签约日期
          if (item === 'signTimeLt') {
            if (
              ltegteDate({
                lte: this.signTimeLt,
                gte: this.signTimeGt,
              })
            ) {
              searchData['signTime'] = ltegteDate({
                lte: this.signTimeLt,
                gte: this.signTimeGt,
              })
            }
          }
          // 处理合同交货日期
          if (item === 'deadlineLt') {
            if (
              ltegteDate({
                lte: this.deadlineLt,
                gte: this.deadlineGt,
              })
            ) {
              searchData['deadline'] = ltegteDate({
                lte: this.deadlineLt,
                gte: this.deadlineGt,
              })
            }
          }
        } else if (Array.isArray(searchForm[item])) {
          // 处理状态
          if (inFn(searchForm['statusList'])) {
            searchData['status'] = inFn(searchForm['statusList'])
          }
        } else {
          if (searchForm[item]) {
            searchData[item] = `like:${searchForm[item]}`
          }
        }
      })
      return searchData
    },
    handleAddProduct(scope) {
      this.currentProduct = {}
      this.currentOrderId = scope.row.id
      this.addProductModalVisible = true
    },
    showProductsByOrder(code) {
      this.$router.push({
        name: 'ProductManage',
        query: {
          orderCode: code,
        },
      })
    },
    handleDeleteContractFile(id) {
      deleteOrderPdf(id).then((res) => {
        this.$message.success('删除合同文件成功！')
        this.$refs.pagination.currentChange(this.currentPageNum)
      })
    },
    handleFileChange(info) {
      if (info.file.status === 'done') {
        this.$refs.pagination.currentChange(this.currentPageNum)
      }
    },
    handleAddOrder() {
      this.currentEditType = '新增'
      this.currentOrder = {}
      this.editModalVisible = true
    },
    handleUpdateOrder(scope) {
      this.currentEditType = '更新'
      this.currentOrder = R.clone(scope.row)
      this.editModalVisible = true
    },
    handleUpdatePayment(scope) {
      this.currentOrder = R.clone(scope.row)
      this.paymentModalVisible = true
    },
    handleOrderModalConfirmed() {
      this.editModalVisible = false
      this.$refs.pagination.currentChange(this.currentPageNum)
    },
    handleOrderModalCanceled() {
      this.editModalVisible = false
    },
    handlePaymentModalConfirmed() {
      this.paymentModalVisible = false
      this.$refs.pagination.currentChange(this.currentPageNum)
    },
    handlePaymentModalCanceled() {
      this.paymentModalVisible = false
    },
    handleProductModalConfirmed() {
      this.addProductModalVisible = false
      this.$refs.pagination.currentChange(this.currentPageNum)
    },
    handleProductModalCanceled() {
      this.addProductModalVisible = false
    },
    handleMakeOrderPlans(scope) {
      this.$set(scope.row, 'isButtonLoading', true)
      makeOrderPlans(scope.row.id, {})
        .then((res) => {
          if (res.data && res.data.count > 0) {
            this.$set(scope.row, 'isButtonLoading', false)
            this.$message.success('成功下达' + res.data.count + '项计划')
            this.$refs.pagination.currentChange(this.currentPageNum)
          } else {
            this.$set(scope.row, 'isButtonLoading', false)
            this.$message.warning('无计划下达成功')
          }
        })
        .catch((err) => {
          this.$set(scope.row, 'isButtonLoading', false)
        })
    },
    getExportIdsFromSelection() {
      this.exportIds = R.uniq(
        this.multipleSelection.map((item) => {
          return item.id
        })
      )
    },
    getExportIdsFromPage() {
      this.exportIds = this.orderList.map((item) => {
        return item.id
      })
    },
    handleExportOrderCommand(command) {
      this.exportLoading = true
      switch (command) {
        case 'SELECTED':
          if (this.multipleSelection.length === 0) {
            this.$message.warning('选择项不能为空！')
            this.exportLoading = false
            break
          }
          this.getExportIdsFromSelection()
          exportOrdersByIds({
            ids: this.exportIds.toString(),
          })
            .then((res) => {
              this.downloadOrder(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        case 'PAGE':
          if (this.orderList.length === 0) {
            this.$message.warning('导出数据不能为！')
            break
          }
          exportCurrentPageOrders({
            pageNum: this.currentPageNum,
            pageSize: this.currentPageSize,
          })
            .then((res) => {
              this.downloadOrder(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        case 'FILTERED':
          exportFilteredOrders(this.columnFilterParams)
            .then((res) => {
              this.downloadOrder(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        case 'ALL': {
          exportAllOrders()
            .then((res) => {
              this.downloadOrder(res)
            })
            .finally(() => {
              this.exportLoading = false
            })
          break
        }
        default:
          this.exportLoading = false
          break
      }
    },
    downloadOrder(blobData) {
      const currentDate = +Date.now()
      downloadItem(
        blobData,
        'application/vnd.ms-excel',
        CONSTANTS.ORDER_FILE_NAME + currentDate + CONSTANTS.EXPORT_FILE_SUFFIX
      )
    },
    handleDownloadTemplate() {
      downloadTemplate('product')
    },
    confirm(formName) {
      const isEdit = this.dialogType === 'edit'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const cloneOrder = deepClone(this.order)
          if (this.order.signTime) {
            cloneOrder.signTime = handleTimeZone(
              formatDateTime(
                typeof this.order.signTime === 'string'
                  ? this.order.signTime
                  : this.order.signTime.getTime()
              )
            ).substring(0, 19)
          }
          if (this.order.deadline) {
            cloneOrder.deadline = handleTimeZone(
              formatDateTime(
                typeof this.order.deadline === 'string'
                  ? this.order.deadline
                  : this.order.deadline.getTime()
              )
            ).substring(0, 19)
          }
          // if (this.order.invoiceDate) cloneOrder.invoiceDate = handleTimeZone(formatDateTime(typeof this.order.invoiceDate === 'string' ? this.order.invoiceDate : this.order.invoiceDate.getTime())).substring(0, 19)
          delete cloneOrder.realDeliverTime
          delete cloneOrder.updateTime
          delete cloneOrder.createTime
          const displayField = [
            {
              orderCode: '订单号',
            },
          ]
          this.handleEditTip(
            this.order.id,
            cloneOrder,
            updateOrder,
            addOrder,
            getOrderById,
            '订单',
            displayField,
            isEdit
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    productConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addProduct = false
          this.handleAddTip(
            this.order.id,
            this.product,
            addProductByOrder,
            '产品'
          )
          this.product = {}
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    paymentConfirm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const clonePayment = deepClone(this.payment)
          delete clonePayment.createTime
          delete clonePayment.updateTime
          if (this.payment.invoiceDate) {
            clonePayment.invoiceDate = handleTimeZone(
              formatDateTime(
                typeof this.payment.invoiceDate === 'string'
                  ? this.payment.invoiceDate
                  : this.payment.invoiceDate.getTime()
              )
            ).substring(0, 19)
          }
          // if (this.payment.invoiceDate.getTime) clonePayment.invoiceDate = handleTimeZone(formatDateTime(this.payment.invoiceDate.getTime())).substring(0, 19)
          this.handlePaymentDialog = false
          if (!this.isUpdatePayment) {
            this.handleAddTip(
              this.order.id,
              clonePayment,
              addPaymentByOrder,
              '支付信息'
            )
            this.payment = {}
          } else {
            const displayField = [
              {
                orderCode: '订单编号',
              },
            ]
            this.handleEditTip(
              this.order.id,
              clonePayment,
              updatePaymentByOrder,
              addPaymentByOrder,
              null,
              '支付信息',
              displayField,
              true
            )
            this.payment = {}
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    jumpToDashboardPage(id) {
      this.$router.push({
        path: '/dashboard',
        query: {
          orderId: id,
        },
      })
    },
    async handleDownloadFile(id, fileName) {
      this.loading = true
      const url = `/orders/${id}/file`
      await handleDownLoad(url, '', fileName)
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.app-container {
  .roles-table {
    margin-top: 30px;
  }

  /deep/ .el-table th {
    border: 1px solid #ebeef5;
  }

  .permission-tree {
    margin-bottom: 30px;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .payment-card {
    .card-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .payment-card-item {
        width: 50%;
        padding: 12px 0;

        div {
          display: inline-block;
          color: #606266;
        }

        div:nth-child(1) {
          width: 100px;
          color: #99a9bf;
          font-weight: bold;
        }
      }
    }
  }
}

.order-table-expand {
  font-size: 0;

  label {
    width: 150px;
    color: #99a9bf;
  }

  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 33.3%;
  }
}

.export-order {
  float: right;
  padding: 5px;
  margin-top: 10px;
  margin-right: 20px;
}

.dia-form input {
  width: 178px;
}

.orderUpload {
  text-align: center;
}

.demo-drawer {
  .demo-drawer__content {
    margin: 30px;

    .el-drawer__body {
      overflow: auto;
    }
  }

  .demo-drawer__footer {
    display: flex;
    justify-content: center;

    button {
      width: 47%;
    }
  }

  .el-drawer__body {
    overflow: auto;
  }
}

.fixed-position {
  position: absolute;
  top: 20px;
  right: 10px;
}

.search-drawer {
  /deep/ .el-drawer__body {
    overflow: auto;
  }
}

.file-link {
  color: #409eff;
}

.filename-delete {
  color: #ff4d4f;
}
</style>
