var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
          staticClass: "modal-container",
          attrs: {
            visible: true,
            title: _vm.modalTitle,
            "mask-closable": false,
            width: "600px",
          },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消"),
              ]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _vm.type === "out"
                ? [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "出库类型", prop: "takeOutType" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            staticStyle: { width: "400px", fontweight: "400" },
                            model: {
                              value: _vm.form.takeOutType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "takeOutType", $$v)
                              },
                              expression: "form.takeOutType",
                            },
                          },
                          [
                            _c("a-radio", { attrs: { value: 0 } }, [
                              _vm._v("普通出库"),
                            ]),
                            _c("a-radio", { attrs: { value: 1 } }, [
                              _vm._v("返修出库"),
                            ]),
                            _c("a-radio", { attrs: { value: 2 } }, [
                              _vm._v("其他出库"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.type === "out"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "物流单号", prop: "trackingNumber" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.form.trackingNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "trackingNumber", $$v)
                          },
                          expression: "form.trackingNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type === "out"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "模板", prop: "template" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          staticStyle: { width: "400px", fontweight: "400" },
                          model: {
                            value: _vm.template,
                            callback: function ($$v) {
                              _vm.template = $$v
                            },
                            expression: "template",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("模板1"),
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v("模板2（渝江）"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type === "save"
                ? [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "类型" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            staticStyle: { width: "400px", fontweight: "400" },
                            model: {
                              value: _vm.cardType,
                              callback: function ($$v) {
                                _vm.cardType = $$v
                              },
                              expression: "cardType",
                            },
                          },
                          [
                            _vm.warehouseType === 4
                              ? _c("a-radio", { attrs: { value: 1 } }, [
                                  _vm._v("废品入库单"),
                                ])
                              : _c("a-radio", { attrs: { value: 0 } }, [
                                  _vm._v("普通入库单"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "备注", prop: "comment" } },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: _vm.placeholder,
                      "max-length": 500,
                      "auto-size": { minRows: 3, maxRows: 5 },
                    },
                    model: {
                      value: _vm.form.comment,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "comment",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.comment",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        2
      ),
      _c(
        "a-modal",
        { attrs: { visible: _vm.modalVisible }, on: { cancel: _vm.onCancel } },
        [
          _c("p", { staticStyle: { margin: "20px" } }, [
            _vm._v(
              "当前出库的任务订单客户合同号不唯一，是否允许同一出库单出库?"
            ),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")]),
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.onNotPermit },
                },
                [_vm._v("不允许")]
              ),
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.onPermit },
                },
                [_vm._v("允许")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }